<template>
    <div>
        <main class="el-main">
            <p style="margin-top: 0;text-align: left;">
                诊单状态:
                <span v-if="State == 0">新建</span>
                <span v-if="State == 1">提交</span>
                <span v-if="State == 2">审核</span>
                <span v-if="State == 3">打回</span>
                <span v-if="State == 6">已下发</span>
                <span v-if="State == 9">已删除</span>
            </p>
            <table border="1" cellspacing="0" cellpadding="0" id="tblA" class="table-condensed">
                <tbody>
                    <tr>
                        <td colspan="10" align="center" class="td-title">客户信息</td>
                    </tr>
                    <tr>
                        <td width="120px" class="td-title">编号:</td>
                        <td width="200px" align="left">{{KID}}</td>
                        <td class="td-title">客户姓名:</td>
                        <td width="120px">{{custmsg.Cust_Name}}</td>
                        <td width="80px" class="td-title">性别:</td>
                        <td width="80px">{{custmsg.Cust_Sex == 0?'男':'女'}}</td>
                        <td class="td-title">年龄:</td>
                        <td width="100px">{{custmsg.Cust_Age}}</td>
                        <td class="td-title">联系方式:</td>
                        <td width="180px">{{custmsg.Tel1}}</td>
                    </tr>
                    <tr>
                        <td class="td-title">职业:</td>
                        <td>{{custmsg.Cust_Job}}</td>
                        <td class="td-title">出生日期:</td>
                        <td>{{Cust_Birthday}}</td>
                        <td class="td-title">身高:</td>
                        <td>{{custmsg.Cust_Height}}</td>
                        <td class="td-title">体重:</td>
                        <td>{{custmsg.Cust_Weight}}</td>
                        <td class="td-title">文化程度:</td>
                        <td>{{custmsg.Cust_Schools}}</td>
                    </tr>
                    <tr>
                        <td align="left" class="td-title">客户编号:</td>
                        <td align="left">{{custmsg.Cust_ID}}</td>
                        <td align="left" class="td-title">家庭住址:</td>
                        <td colspan="7">{{custmsg.Provice+custmsg.City+custmsg.Country+custmsg.Address}}</td>
                    </tr>
                    <tr>
                        <td class="td-title">主治医生:</td>
                        <td>{{custmsg.doc_name}}</td>
                        <td class="td-title">责任客服:</td>
                        <td>{{custmsg.Cust_Servicer}}</td>
                        <td class="td-title">诊单状态:</td>
                        <td colspan="2">
                            <template>
                                <span v-if="State == 0">新建</span>
                                <span v-if="State == 1">提交</span>
                                <span v-if="State == 2">审核</span>
                                <span v-if="State == 3">打回</span>
                                <span v-if="State == 6">已下发</span>
                                <span v-if="State == 9">已删除</span>
                            </template>
                        </td>
                        <td class="td-title">创建时间:</td>
                        <td colspan="3">{{custmsg.Create_Date}}</td>
                    </tr>
                    <tr>
                        <td colspan="10" align="center" class="td-title">客户症状体征</td>
                    </tr>
                    <tr>
                        <td class="td-title">症状和体征</td>
                        <td colspan="9">
                            <p v-for="(item,index) in list" :key="index">
                                {{item.KFiel_ShowCaption}}：{{item.KFValues}}<br></p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <h2 style="margin-top: 0;text-align: left;">治疗方案：</h2>
            <table border="1" cellspacing="0" cellpadding="0" id="tblA" class="table-condensed">
                <tbody>
                    <tr>
                        <td colspan="6" align="center" class="td-title">方案内容</td>
                    </tr>
                    <tr>
                        <td class="td-title">饮食方案</td>
                        <td colspan="5">{{fa.YSTZFA}}</td>
                    </tr>
                    <tr>
                        <td class="td-title">治疗方案</td>
                        <td colspan="5">{{fa.YDFA}}</td>
                    </tr>
                    <tr>
                        <td class="td-title">指导意见</td>
                        <td colspan="5">{{fa.BZ}}</td>
                    </tr>
                </tbody>
            </table>
            <el-tabs v-model="activeName" class="update-solution">
                <el-tab-pane label="相关附件" name="first">
                    <p class="pic" v-for="(item,index) in photo" :key="index">
                        <a :href="'http://192.168.11.253:8080/index.php/photo/view?photo='+item.FileName"
                            target="_blank">
                            <img :src="'http://192.168.11.253:8080/index.php/photo/view?photo='+item.FileName" />
                        </a>
                    </p>
                </el-tab-pane>
            </el-tabs>
        </main>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                KID: '',
                Cust_ID: '',
                list: '',
                fa: {},
                photo: [],
                custmsg: {},
                State: '',
                activeName: 'first',
                Cust_Birthday:'',
            }
        },
        mounted() {
            this.KID = this.$route.query.id;
            this.Cust_ID = this.$route.query.cust_id;
            this.State = this.$route.query.state;
            this.axios.get("/field/get_z_field_detail?kid=" + this.KID+"&input_id=" + sessionStorage.getItem("servicer_id"))
                .then((res) => {
                    if (res.data.code == 0) {
                        this.list = res.data.result;
                        if (res.data.fa != '' && res.data.fa != null && res.data.fa != undefined) {
                            this.fa = res.data.fa;
                        }
                        if (res.data.photo != '' && res.data.photo != null && res.data.photo != undefined) {
                            this.photo = res.data.photo;
                        }
                    } else {
                        console.log(res)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            this.axios.get("/gu/get_cust_info_by_custid?cust_id=" + this.Cust_ID)
                .then((res) => {
                    if (res.data.code == 0) {
                        this.custmsg = res.data.result;
                        this.Cust_Birthday = res.data.result.Cust_Birthday.split(' ')[0];
                    } else {
                        console.log(res)
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    }
</script>
<style>
    .el-main {
        display: block;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        overflow: auto;
        padding: 20px;
        width: 100%;
    }

    #tblA {
        width: 100%;
        margin: auto;
        text-align: left;
        border-spacing: 0;
        border-collapse: collapse;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 2px solid #000;
    }

    table {
        display: table;
        border-collapse: separate;
        white-space: normal;
        line-height: normal;
        font-weight: 400;
        font-size: medium;
        font-style: normal;
        color: -internal-quirk-inherit;
        text-align: start;
        border-spacing: 2px;
        border-color: grey;
        font-variant: normal;
    }

    #tblA td {
        display: table-cell;
        vertical-align: inherit;
        padding: 5px 2px;
    }

    #tblA td,
    #tblB td {
        padding: 5px;
    }

    .td-title {
        font-weight: 600;
    }

    .update-solution {
        margin: 30px auto;
        width: 100%;
    }

    .pic {
        width: 150px;
        height: 150px;
        float: left;
        margin-right: 20px;
        position: relative;
        font-size: 20px;
    }

    .pic i {
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 500;
        cursor: pointer;
    }

    .pic i:hover {
        color: #409EFF;
    }

    .pic a img {
        width: 100%;
        height: 100%;
    }
</style>